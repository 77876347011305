<script lang="ts" setup>
import Headerbar from '@/components/Header/Headerbar.vue'
import MobileBottom from '@/components/Mobile/MobileBottom.vue'
import MobileMenuBox from '@/components/Mobile/MobileMenuBox.vue'
import FooterBar from '@/components/Footer/FooterBar.vue'
import Copyright from '@/components/Footer/Copyright.vue'
import GlobalDialog from '@/components/GlobalDialog.vue'
import { usePageStore, useGlobalStore, useOptionStore } from '@/stores'

const globalStore = useGlobalStore()
const optionStore = useOptionStore()
const showLayout = computed(() => globalStore.showLayout)

const pageStore = usePageStore()
// 載入首頁資料，但不重刷
if (!pageStore.getIsLoadData) {
  await pageStore.fetchGetHomepage()
}
const { advert } = useApi()
const { data: advertData } = await advert.getAdvert('all-site')
const advertRes = computed(() => advertData.value?.data)

onMounted(() => {
  nextTick(async () => {
    optionStore.fetchAddress()
  })
})
</script>

<template>
  <div class="outerWrap" :class="{ dark: globalStore.isDark, white1: globalStore.isWhite1 }" v-if="showLayout">
    <Headerbar class="isFixed" />
    <Breadcrumb v-if="!globalStore.isDark" />
    <main class="main">
      <div class="wrap">
        <slot name="content" />
      </div>
    </main>
    <FooterBar class="ins" />
    <Copyright />
    <MobileMenuBox />
    <DialogCover :data="advertRes" v-if="advertRes" />
    <MobileBottom />
    <GlobalDialog />
  </div>
</template>

<style lang="scss" scoped>
.outerWrap {
  padding-top: toRem(88);
  overflow: hidden;
  min-height: 100vh;
  background-color: $color-white-2;

  &.dark {
    background-color: $color-black-1;
  }

  &.white1 {
    background-color: $color-white-1;
  }
}
</style>
