<script lang="ts" setup>
import { useGlobalStore } from '@/stores'
import { storeToRefs } from 'pinia'
const globalStore = useGlobalStore()
const { breadcrumbs } = storeToRefs(globalStore)

const isShow: Ref<boolean> = ref(false)

onMounted(() => {
  setTimeout(() => {
    isShow.value = true
  }, 100)
})
</script>

<template>
  <div class="wrap">
    <ul class="bread" :class="{ isShow: isShow }">
      <li>
        <nuxt-link to="/" class="bread__item">首頁</nuxt-link>
      </li>
      <li v-for="(item, index) in breadcrumbs" :key="index">
        <nuxt-link :to="item.url" class="bread__item" :class="{ isActive: item.isActive }"
          :title="item.orgTitle || item.title" v-if="item.url">
          {{ item.title }}
        </nuxt-link>
        <div class="bread__item" :class="{ isActive: item.isActive }" v-else>
          {{ item.title }}
        </div>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.bread {
  position: relative;
  z-index: 5;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  border-top: toRem(1) solid $color-gray-line;
  padding-top: toRem(20);
  padding-bottom: toRem(20);
  align-items: center;

  &.white {
    border-top-color: $color-white-1;
  }

  li {
    opacity: 0;
    transform: translateY(30px);
    transition: all 0.8s ease-in-out;
  }

  &.isShow {
    li {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @include max-media(480) {
    justify-content: center;
  }

  &__item {
    font-size: toRem(14);
    color: $color-gray-3;

    &.isActive {
      color: $color-gray-1;
    }

    &:not(.isActive) {
      position: relative;
      padding-right: toRem(30);

      &::after {
        content: '';
        position: absolute;
        right: toRem(13);
        top: 50%;
        margin-top: toRem(-2);
        width: toRem(4);
        height: toRem(4);
        background-color: $color-gray-3;
      }
    }
  }
}
</style>
